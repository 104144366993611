










































.cusomer-drop {
  display: flex !important;
  flex-direction: rows;
  .btn {
    padding: 0;
    margin: 0;
    display: flex !important;
    flex-direction: rows;
    align-items: center;
    outline: none !important;
    box-shadow: none !important;
  }
}
